// 工单类型相关接口
import request from "@/utils/request";
// 获取归属类型列表
export function workCateList(data) {
  return request("get", "/apm/workSheet/type/sheetTypeList", data);
}
// 新增或编辑工单类型
export function updata(data) {
  return request("post", "/apm/workSheet/type/mod", data);
}
// 查询工单类型列表
export function workTypeList(data) {
  return request("post", "/apm/workSheet/type/list", data);
}
// 删除工单类型
export function delType(data) {
  return request("post", "/apm/workSheet/type/del", data);
}